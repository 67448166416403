import GooglePlayPngZh from '@/assets/images/install-channels/google-play-zh.png';
import GooglePlayPngTw from '@/assets/images/install-channels/google-play-tw.png';
import GooglePlayPngEn from '@/assets/images/install-channels/google-play-en.png';
import GooglePlayPngId from '@/assets/images/install-channels/google-play-id.png';
import GooglePlayPngMs from '@/assets/images/install-channels/google-play-ms.png';
import GooglePlayPngKo from '@/assets/images/install-channels/google-play-ko.png';
import DownloadApkPngZh from '@/assets/images/install-channels/android-zh.png';
import DownloadApkPngTw from '@/assets/images/install-channels/android-tw.png';
import DownloadApkPngEn from '@/assets/images/install-channels/android-en.png';
import DownloadApkPngId from '@/assets/images/install-channels/android-id.png';
import DownloadApkPngMs from '@/assets/images/install-channels/android-ms.png';
import DownloadApkPngKo from '@/assets/images/install-channels/android-ko.png';
import AppStorePngZh from '@/assets/images/install-channels/app-store-zh.png';
import AppStorePngTw from '@/assets/images/install-channels/app-store-tw.png';
import AppStorePngEn from '@/assets/images/install-channels/app-store-en.png';
import AppStorePngId from '@/assets/images/install-channels/app-store-id.png';
import AppStorePngMs from '@/assets/images/install-channels/app-store-ms.png';
import AppStorePngKo from '@/assets/images/install-channels/app-store-ko.png';
import GalaxyPngZh from '@/assets/images/install-channels/galaxy-zh.png';
import GalaxyPngTw from '@/assets/images/install-channels/galaxy-tw.png';
import GalaxyPngEn from '@/assets/images/install-channels/galaxy-en.png';
import GalaxyPngId from '@/assets/images/install-channels/galaxy-id.png';
import GalaxyPngMs from '@/assets/images/install-channels/galaxy-ms.png';
import GalaxyPngKo from '@/assets/images/install-channels/galaxy-ko.png';

const iconMapping = {
  'zh-CN': {
    googlePlayPng: GooglePlayPngZh,
    androidPng: DownloadApkPngZh,
    galaxyPng: GalaxyPngZh,
    appStorePng: AppStorePngZh,
  },
  'zh-TW': {
    googlePlayPng: GooglePlayPngTw,
    androidPng: DownloadApkPngTw,
    galaxyPng: GalaxyPngTw,
    appStorePng: AppStorePngTw,
  },
  'en-US': {
    googlePlayPng: GooglePlayPngEn,
    androidPng: DownloadApkPngEn,
    galaxyPng: GalaxyPngEn,
    appStorePng: AppStorePngEn,
  },
  id: {
    googlePlayPng: GooglePlayPngId,
    androidPng: DownloadApkPngId,
    galaxyPng: GalaxyPngId,
    appStorePng: AppStorePngId,
  },
  ms: {
    googlePlayPng: GooglePlayPngMs,
    androidPng: DownloadApkPngMs,
    galaxyPng: GalaxyPngMs,
    appStorePng: AppStorePngMs,
  },
  ko: {
    googlePlayPng: GooglePlayPngKo,
    androidPng: DownloadApkPngKo,
    galaxyPng: GalaxyPngKo,
    appStorePng: AppStorePngKo,
  },
};

export default new Proxy(iconMapping, {
  get(obj, key) {
    if (!Object.keys(iconMapping).includes(key)) {
      return obj['en-US'];
    }
    return obj[key];
  },
});
